<template>
  <v-btn
    class="v-btn--vertical"
    :class="[
      small ? 'v-btn--vertical-sm' : '',
      loading ? 'v-btn--vertical-loading' : '',
    ]"
    height="auto"
    :text="filled ? undefined : true"
    elevation="0"
    :to="to ? to : null"
    :target="target ? target : null"
    :href="href ? href : null"
    v-on="on ? on : null"
    v-bind="attrs ? attrs : null"
    :disabled="disabled"
  >
    <div class="d-flex flex-column align-center justify-space-between">
      <v-icon
        v-if="!loading"
        size="24"
        :class="color ? `${color}--text` : `heading--text`"
        >{{ icon }}</v-icon
      >
      <div v-else class="d-flex justify-center align-center loading-container">
        <loader
          :color="color ? color : 'primary'"
          size="20"
          :noMargins="true"
        />
      </div>
      <span
        class="p-5 v-btn__text"
        :class="[color ? `${color}--text` : '', loading ? 'loading' : '']"
        v-html="text"
      ></span>
    </div>
  </v-btn>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    small: Boolean,
    icon: String,
    text: String,
    to: String,
    href: String,
    on: Object,
    attrs: Object,
    color: String,
    filled: Boolean,
    target: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  .loading-container {
    min-width: 34px;
    min-height: 34px;
  }

  &--vertical {
    min-height: 64px;
    padding: 0px 8px !important;
    min-width: 46px;
    &::before {
      display: none;
    }
    .v-btn__text,
    .v-icon {
      transition: color 0.3s ease;
    }

    &.v-size--default {
      min-width: 46px;
    }
    .v-btn__text {
      font-size: $font-size-xxs;
      line-height: $line-height-xxs;
      font-weight: $font-weight-normal;
      color: map-get($gray, darken1);
      max-height: 14px;
      display: inline-block;
    }

    .v-icon {
      margin-right: 0px;
      margin-bottom: 8px;
    }
    &.v-btn--disabled {
      .v-icon {
        color: #adadad !important;
      }
    }
    &:hover,
    &.v-btn--vertical-loading {
      .v-btn__content {
        .v-btn__text,
        .v-icon {
          color: map-get($primary, base) !important;
          &.info--text {
            color: map-get($info, base) !important;
          }
        }
      }
    }
    &-sm {
      // min-height: 46px !important;
      // max-height: 46px !important;
      min-width: 46px !important;
      max-width: 46px !important;
      .v-btn__text {
        width: 46px;
        white-space: normal;
      }
    }
  }
  &.primary-btn {
    &::v-deep {
      .v-btn__text {
        color: map-get($primary, darken1);
      }
      .v-icon {
        color: map-get($primary, darken1) !important;
      }
    }
  }
}
.v-application--is-rtl{
    .v-btn--vertical{
        .v-icon{
            margin-left: 0px;
        }
    }
}
</style>
